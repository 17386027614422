import { findIndex, isEmpty } from 'lodash'
import { observable, action } from 'mobx'
import { DATE_FORMAT_CLIENT_PARAMS, PAGE_SIZE, statusOrder } from '../helpers/constants'
import api from '../common/api'
import { Request2 } from 'src/common/Request2'
import { responseDefault } from './store.constants'
import { showMessageError } from 'src/helpers/functions'
import moment from 'moment'

export class OrdersStore {
   @observable offsetList: number = 0
   @observable orders: any = { ...responseDefault }
   @observable ordersRecruiting: any = { ...responseDefault } //NEW: 0,
   @observable ordersProcessing: any = { ...responseDefault } //    DOING: 1,
   @observable ordersReview: any = { ...responseDefault } //    REVIEW: 2,
   @observable ordersEditing: any = { ...responseDefault } //    REJECT: 3,
   @observable ordersDone: any = { ...responseDefault } //    FINISH: 4,
   @observable ordersCount: any = {}
   @observable textSearch: string = ''
   @observable fieldFilter: any = {}
   @observable ordersTotal: any = {}
   @observable products: any = { ...responseDefault }
   @observable listStores: any = []
   @observable listHistory: any = []
   @observable productsSelected: any = []
   @observable orderDetail: any = {}
   @observable sellerId: any = null
   @observable dataExport: any = { ...responseDefault }
   @observable shippingFee: number = 0

   //LIST
   @observable startTime: any = moment().subtract(1, 'months')
   @observable endTime: any = moment()
   // const [startTime, setStartTime] = useState(moment().subtract(1, 'months'))
   // const [endTime, setEndTime] = useState(moment())
   @observable statusObj: any = {
      new: [statusOrder.NEW], //new+renew
      accepted: [statusOrder.ACCEPTED],
      assignee: [statusOrder.ASSIGNEE],
      finish: [statusOrder.FINISH],
      // renew: [statusOrder.RE_NEW],
      cancel: [statusOrder.CANCEL]
   }
   //LIST 
   @action
   setStartTime(value: any) {
      this.startTime = value
   }
   @action
   setEndTime(value: any) {
      this.endTime = value
   }
   @action
   setStatusObj(statusObj: any) {
      this.statusObj = { ...statusObj }
   }

   @action
   async getOrderList(params: any = {}) {
      const statusResult = [
         ...this.statusObj.new,
         ...this.statusObj.accepted,
         ...this.statusObj.assignee,
         ...this.statusObj.cancel,
         // ...statusObj.renew,
         ...this.statusObj.finish
      ].toString()

      const param = {
         ...params, ...{
            status: statusResult,
            from: moment(this.startTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
            to: moment(this.endTime).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
         }
      }
      const result = await Request2.getWithToken(api.order.getList, { ...param, ...{ limit: PAGE_SIZE } })
      if (!isEmpty(result)) {
         this.orders = result
         this.offsetList = result.offset
      } else {
         this.orders = { ...responseDefault }
         this.offsetList = 0
      }
      console.log('81OrdersStore.orders.offset', this.orders);

   }

   @action
   async getProductsList(params: any = {}) {
      const result = await Request2.getWithToken(api.products.getList, { ...params })
      if (!isEmpty(result)) {
         this.products = result
      }
   }
   @action
   async getListStores(id: string) {
      const result = await Request2.getWithToken(api.order.listStore + `/${id}`)
      if (!isEmpty(result)) {
         const dt = result.filter((it: any) => !it?.disable)
         this.listStores = dt
      }
   }

   async getListStoreMultiOrder(orderIds: any = {}) {
      const result = await Request2.getWithToken(api.order.listStoreMultiOrder, orderIds)
      if (!isEmpty(result)) {
         const dt = result.filter((it: any) => !it?.disable)
         this.listStores = dt
      }
   }

   @action
   async getProducts(params: any = {}) {
      let param = { ...params }
      // if (param?.isPriority) {
      //    param.isPriority = true
      // } else {
      //    delete param['isPriority']
      // }
      const result = await Request2.getWithToken(api.products.getList, { ...param, ...{ limit: PAGE_SIZE } })

      if (!isEmpty(result)) {
         this.products = result
      } else {
         this.products = { ...responseDefault }
      }
   }

   @action
   setProductsSelected(list: any) {
      if (list.length == 0) {
         this.productsSelected.length = 0
         return
      }
      this.productsSelected = [...list]
   }

   @action
   removeProduct(index: number) {
      this.productsSelected.splice(index, 1)
   }

   @action
   async createOrder(params: any = {}) {
      const result = await Request2.postWithToken(params, api.order.create)
      return result
   }
   @action
   async updateTrackingId(id: string, trackingId: string, saigonId?: string, sellerOrderId?: string) {
      let param = { trackingId }
      //@ts-ignore
      if (saigonId) { param.saigonId = saigonId }
      //@ts-ignore
      if (sellerOrderId) { param.sellerOrderId = sellerOrderId }
      const result = await Request2.postWithToken(param, api.order.update + `/${id}`)
      return result
   }
   @action
   async cancelOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.cancel + `/${id}`)
      return result
   }
   @action
   async confirmOrder(params: any) {
      const result = await Request2.postWithToken(params, api.order.confirm)
      return result
   }
   @action
   async assigneeOrder(params: any) {
      const result = await Request2.postWithToken(params, api.order.assignee)
      return result
   }

   @action
   async getDetail(id: any) {
      const result = await Request2.getWithToken(api.order.detail + `/${id}`)
      return result
   }

   @action
   async finishOrder(id: string, body: any) {
      const result = await Request2.postWithToken(body, api.order.finishOrder + `/${id}`)
      return result
   }

   @action
   async setSellerId(id: any) {
      if (this.sellerId == id) return (this.sellerId = null)

      this.sellerId = id
   }
   @action
   async setOrderList(list: any) {
      if (!list || list.length == 0) return
      this.orders = list
   }

   @action
   async exportExcel(param: any = {}) {
      const result = await Request2.getWithToken(api.order.exportExcel, { ...param })

      if (!isEmpty(result)) {
         this.dataExport = result
      }
   }
   async removeOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.removeOrder + `/${id}`)
      return result
   }

   @action
   async getShippingFee(params: any) {
      const result = await Request2.postWithToken(params, api.order.getShipingFee)

      if (!isEmpty(result)) {
         this.setShippingFee(result.data)
      }
   }

   @action
   setShippingFee(fee: number) {
      this.shippingFee = fee
   }

   @action
   async refundOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.refundOrder + `/${id}`)
      return result
   }
   @action
   async pushOrder(id: string) {
      const result = await Request2.postWithToken({}, api.order.pushOrder + `/${id}`)
      return result
   }
   @action
   async pushOrders(ids: any) {
      const result = await Request2.postWithToken({ orderIds: ids }, api.order.pushOrder)
      return result
   }

   @action
   async getListHistory(id: any) {
      const result = await Request2.getWithToken(api.order.getListHistory + `/${id}`)
      if (result) {
         this.listHistory = result
      } else {
         this.listHistory = []
      }
   }

   @action
   async updateStatusOrder(id: any, statusFF: string) {
      const result = await Request2.postWithToken({ statusFF }, api.order.updateStatusFF + `/${id}`)
      if (result?.code) {
         const dataTemp = { ...this.orders }
         const indexOrderId = findIndex(dataTemp?.rows, (o: any) => {
            return o?.id == id
         })

         if (indexOrderId >= 0) {
            if (this.orders?.rows?.[indexOrderId]) {

               const dataClone = [...this.orders?.rows]
               dataClone[indexOrderId].statusFF = statusFF

               this.orders.rows = [...dataClone]
            }
         }
      }
   }
}
