import { useEffect, useState } from 'react'
import { Table, Space, Col, Row, Pagination, Checkbox, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { useStore } from '../../hooks'
import { DATE_FORMAT_CLIENT_PARAMS, FILTER_TIME, PAGE_SIZE_CHAT, getPage } from '../../helpers/constants'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
// import Create from './Create'
import useWindowDimensions from '../../common/useWindowDimensions'
// import Detail from './Detail'
import moment, { Moment } from 'moment'
import 'moment/locale/vi'
import { find, findIndex, get, isEmpty, map } from 'lodash'
import Chart from './Chart'
import Transaction from './Transaction'

const ContentTab = observer((props: any) => {
   const { startTime, endTime, type, typeFilter } = props
   const { t } = useTranslation()
   const DashboardStore = useStore('DashboardStore')
   const { height } = useWindowDimensions()
   const WarehouseStore = useStore('WarehouseStore')
   const UserStore = useStore('UserStore')
   const [sortDateDesc, setSortDateDesc] = useState(true)
   const [visibleDetail, setVisibleDetail] = useState(false)
   const [visibleDetailTransaction, setVisibleDetailTransaction] = useState(false)
   const [item, setItem] = useState(null)
   const [hideZero, setHideZero] = useState(true)
   const { rows, count } = DashboardStore.listJobFinish
   const [fieldFilter, setFieldFilter] = useState<any>({})
   const { rows: listWarehouse } = WarehouseStore.warehouseList
   const { rows: listSeller } = UserStore.listSeller
   // const listWarehouse = rows.filter((item: any) => item.name !== 'MAIN_STORE')

   useEffect(() => {
      if (!listSeller || listSeller.length < 1) {
         getListSeller()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   useEffect(() => {
      if (rows.length > 0 && DashboardStore.listDataInventory.length > 0) {
         const rowTemp: any = [...rows]
         DashboardStore.listDataInventory.map((v: any) => {
            const indexRow = findIndex(rowTemp, (o: any) => {
               return o?.name == v?.name
            })
            if (indexRow >= 0) {
               if (rowTemp[indexRow]) {
                  rowTemp[indexRow]['inventory'] = v?.inventory
               }
            }
         })
      }
   }, [rows, DashboardStore.listDataInventory])

   useEffect(() => {
      console.log('fieldFilter', fieldFilter)

      handle_getListData(0, { from: startTime, to: endTime, type, hideZeroCheck: hideZero, ...fieldFilter })
      getInventoryAll()
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [startTime, endTime, type, hideZero, fieldFilter])

   useEffect(() => {
      if (listWarehouse.length <= 0) {
         getListData()
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getInventoryAll = () => {
      DashboardStore.getInventoryAll()
   }

   const getListSeller = async () => {
      try {
         // setIsLoading(true)
         const offset = getPage(0)
         const params = { offset }
         // console.log('params', params)

         await UserStore.getSeller(params)
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }

   const getListData = async () => {
      try {
         // setIsLoading(true)
         const offset = getPage(0)
         const params = { offset }
         //  console.log('params', params)

         await WarehouseStore.getWarehouseList(params)
      } catch (error) {
         //  console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const handle_getListData = async (
      currentPage: number,
      params: { from: Moment; to: Moment; type: string; hideZeroCheck: boolean; storeId?: any; sellerIds?: any }
   ) => {
      try {
         // setIsLoading(true)
         // const offset = getPage(currentPage)
         var param = {
            // offset,
            ...{
               from: moment(params.from).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               to: moment(params.to).format(DATE_FORMAT_CLIENT_PARAMS).toString(),
               hideZero: params.hideZeroCheck ? 1 : 0,
               type,
               storeId: params?.storeId,
               sellerIds: params?.sellerIds
            }
         }
         // console.log('{ from: Moment; to: Moment; type: string; hideZeroCheck: boolean, filter: any }',);

         console.log('params', params, 'param', param)
         await DashboardStore.getJobFinish(param)
      } catch (error) {
         // console.log(error)
      } finally {
         // setIsLoading(false)
      }
   }

   const showDetail = (value: any) => {
      setItem(JSON.parse(JSON.stringify(value)))
      // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))
      if (type == 0) {
         setTimeout(() => {
            setVisibleDetail(true)
         }, 500)
      }
   }

   const showDetailTransaction = (value: any) => {
      if (value?.userId) {
         setItem(JSON.parse(JSON.stringify(value)))
         // console.log('showDetail; ', JSON.parse(JSON.stringify(value)))

         setTimeout(() => {
            setVisibleDetailTransaction(true)
         }, 500)
      }
   }

   const handleEdit = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetail = () => {
      setVisibleDetail(false)
      setItem(null)
   }

   const hideDetailTransaction = () => {
      setVisibleDetailTransaction(false)
      setItem(null)
   }
   let columns = [
      {
         title: '',
         dataIndex: 'name',
         key: 'name',
         align: 'center' as 'center',
         width: 120,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span
                  onClick={() => showDetailTransaction(record)}
                  className={`txtMax2Line ${record?.userId ? 'gx-link' : ''}`}
               >
                  {value}
               </span>
            )
         }
      }
   ]
   if (type === 1) {
      columns.push({
         title: 'Tồn kho',
         dataIndex: 'inventory',
         key: 'inventory',
         align: 'center' as 'center',
         width: 110,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span onClick={() => showDetail(record)} className={`txtMax2Line gx-link`}>
                  {value || 0}
               </span>
            )
         }
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      })
      columns.push({
         title: 'Tổng SL',
         dataIndex: 'total',
         key: 'total',
         align: 'center' as 'center',
         width: 110,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span onClick={() => showDetail(record)} className={`txtMax2Line gx-link`}>
                  {value}
               </span>
            )
         }
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      })
   } else {
      columns.push({
         title: 'Tổng SL',
         dataIndex: 'total',
         key: 'total',
         align: 'center' as 'center',
         width: 110,
         fixed: 'left',
         render: (value: any, record: any) => {
            return (
               <span onClick={() => showDetail(record)} className={`txtMax2Line gx-link`}>
                  {value}
               </span>
            )
         }
         // fixed: 'left',
         //  render: (value: string, record: any, index: number) =>
         //      (page-1) * PAGE_SIZE + index + 1,
      })
   }

   if (rows && get(rows, '[0]', { data: [] })) {
      //@ts-ignore
      map(get(rows, '[0].data', []), (customer: any, index: number) => {
         // @ts-ignore
         columns.push({
            title: moment(customer?.day, 'DD/MM/YYYY').format('DD/MM'),
            dataIndex: customer?.day || '',
            width: 80,
            key: customer?.day,

            render: (value: any, object: any) => {
               // console.log('object', JSON.parse(JSON.stringify(object)), 'value', JSON.parse(JSON.stringify(value)))
               return <span className="txtMax2Line">{object[`${customer?.day}`]?.value || 0}</span>
            }
         })
      })
   }

   // const renderPagination = () => {
   //    if (isEmpty(rows)) {
   //       return null
   //    }

   //    return (
   //       <div className="containerPagination">
   //          <Pagination
   //             onChange={onChange}
   //             pageSize={PAGE_SIZE_CHAT}
   //             current={offset / PAGE_SIZE_CHAT + 1}
   //             showSizeChanger={false}
   //             total={count}
   //          />
   //       </div>
   //    )
   // }

   function onChangeTable(pagination: any, filters: any, sorter: any, extra: any) {
      // console.log('params', pagination, filters, sorter, extra)
      if (sorter.order === 'ascend') {
         setSortDateDesc(false)
      } else {
         setSortDateDesc(true)
      }
   }

   const handleChangeStore = (value: any) => {
      console.log('value', value)
      if (value == fieldFilter.storeId || !value) {
         let x = { ...fieldFilter }
         delete x['storeId']
         setFieldFilter(x)
      } else {
         setFieldFilter({ ...fieldFilter, ...{ storeId: value } })
      }
   }
   const handleChangeSeller = (value: any) => {
      console.log('value', value)
      if (value == fieldFilter.sellerIds || !value) {
         let x = { ...fieldFilter }
         delete x['sellerIds']
         setFieldFilter(x)
      } else {
         setFieldFilter({ ...fieldFilter, ...{ sellerIds: value } })
      }
   }

   return (
      <>
         <Row>
            <Col span={24}>
               {type == 1 && (
                  <Row style={{ marginBottom: 8 }}>
                     <Col xs={24} md={12}>
                        <Select
                           value={fieldFilter.sellerIds}
                           placeholder="Chọn seller"
                           mode="tags"
                           style={{ width: 140, marginRight: 8 }}
                           onChange={handleChangeSeller}
                        >
                           {/* <Select.Option key={''} value={''}>
                              {'Tất cả'}
                           </Select.Option> */}
                           {listSeller.map((item: any) => {
                              return (
                                 <Select.Option key={item?.id + ''} value={item?.id + ''}>
                                    {item?.name}
                                 </Select.Option>
                              )
                           })}
                        </Select>
                        <Select
                           value={fieldFilter.storeId}
                           clearIcon
                           placeholder="Chọn kho"
                           onChange={handleChangeStore}
                           style={{ width: 140 }}
                        >
                           <Select.Option key={'-1'} value={'-1'}>
                              {'Tất cả'}
                           </Select.Option>
                           {listWarehouse.map((item: any) => {
                              return (
                                 <Select.Option key={item?.id} value={item?.id}>
                                    {item?.name}
                                 </Select.Option>
                              )
                           })}
                        </Select>
                     </Col>
                     <Col xs={24} md={12}></Col>
                  </Row>
               )}
               <Row style={{ marginBottom: 8 }}>
                  <Col xs={24} md={12}>
                     <div className="total-items">
                        {t(GLOBAL_CLIENT.totalItems)}: {rows?.length}
                     </div>
                     {/* <Input.Search onSearch={handleChangeTextSearch} placeholder={t(GLOBAL_CLIENT.search)} allowClear /> */}
                  </Col>
                  <Col xs={24} md={12}>
                     <div className="text-right">
                        <Space>
                           <Checkbox
                              style={{ paddingLeft: 4, paddingRight: 4 }}
                              defaultChecked={hideZero}
                              onChange={(e) => setHideZero(e?.target?.checked)}
                           >
                              {'Ẩn Tổng =0'}
                           </Checkbox>
                           {/* <Button className="btn-add">
                              <span style={{ fontWeight: 'bold', paddingRight: 8 }}>Tổng mẫu</span>
                              <span style={{ fontSize: 20, color: '#89AD88', fontWeight: 'bolder' }}>{`${formatCurrency(
                                 DashboardStore.totalJobFinish,
                                 true
                              )}`}</span>
                           </Button> */}
                        </Space>
                     </div>
                  </Col>
               </Row>

               <Table
                  // scroll={{ x: 1200 }}
                  scroll={{ y: height - 300, x: typeFilter === FILTER_TIME[0].type ? 1000 : 2000 }}
                  //@ts-ignore
                  columns={columns}
                  dataSource={rows}
                  rowKey="id"
                  pagination={false}
                  onChange={onChangeTable}
               />

               {/* {renderPagination()} */}
            </Col>
         </Row>
         {visibleDetail && item && type === 0 && (
            <Chart
               visible={visibleDetail}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetail}
               startTime={startTime}
               endTime={endTime}
            />
         )}
         {visibleDetailTransaction && item && (
            <Transaction
               visible={visibleDetailTransaction}
               item={item}
               onOk={handleEdit}
               onCancel={hideDetailTransaction}
               startTime={startTime}
               endTime={endTime}
               role={type}
            />
         )}
      </>
   )
})

export default ContentTab
