import { useEffect, useState } from 'react'
import { Input, Form, Space, Button, Select } from 'antd'
import { observer } from 'mobx-react-lite'
import { GLOBAL_CLIENT } from '../../helpers/GlobalClient'
import { useTranslation } from 'react-i18next'
import { useStore } from '../../hooks'
import { debounce } from 'lodash'

const formItemLayout = {
   labelCol: {
      xs: { span: 24 },
      sm: { span: 8 }
   },
   wrapperCol: {
      xs: { span: 24 },
      sm: { span: 16 }
   }
}

const ConfigStatus = observer((props: any) => {
   const { t } = useTranslation()

   // eslint-disable-next-line @typescript-eslint/no-unused-vars
   const [isLoading, setIsLoading] = useState(false)
   const UserStore = useStore('UserStore')
   const [form] = Form.useForm()

   const [option, setOption] = useState<any>([])
   const [value, setValue] = useState<any>()

   useEffect(() => {
      getConfigFF()

      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [])

   const getConfigFF = async () => {
      try {
         await UserStore.action_get_config()

         const listOptionTemp = (UserStore.listConfigFF || '').split(',').map((v: string) => {
            return { value: v, label: v }
         })

         setOption(listOptionTemp)
         const values = (UserStore.listConfigFF || '').split(',').map((v: string) => {
            return v
         })
         setValue(values)
      } catch (error) {
         // console.log(error)
      } finally {
      }
   }

   const onFinish = async () => {
      if (value) {
         await UserStore.action_update_config(value.join(','))
      }
   }

   const handleChange = (value: any) => {
      setValue(value)
   }

   return (
      <div>
         <Select
            mode="tags"
            style={{ width: '100%' }}
            onChange={handleChange}
            placeholder="Tags Mode"
            options={option}
            value={value}
            inputValue=""
         />

         <div className="text-right gx-mt-2">
            <Space>
               <Button type="primary" className="btn-add" onClick={onFinish}>
                  {t(GLOBAL_CLIENT.apply)}
               </Button>
            </Space>
         </div>
      </div>
   )
})

export default ConfigStatus
