export const GLOBAL_CLIENT = {
   txtList: 'txtList',
   trangthai: 'trangthai',
   txtCreate: 'txtCreate',
   tatca: 'tatca',
   danhan: 'danhan',
   dahuy: 'dahuy',
   vuilongnhapdulieu: 'vuilongnhapdulieu',
   vuilongnhapdungdinhdang: 'vuilongnhapdungdinhdang',
   txtPrice: 'txtPrice',
   dongy: 'dongy',
   huy: 'huy',
   txtUpdate: 'txtUpdate',
   txtSearch: 'txtSearch',
   male: 'male',
   female: 'female',
   vuilongtaianh: 'vuilongtaianh',
   tailen: 'tailen',
   txtEmail: 'txtEmail',
   txtPhone: 'txtPhone',
   txtCusName: 'txtCusName',
   gioitinh: 'gioitinh',
   txtAddress: 'txtAddress',
   facebook: 'facebook',
   loaigiayto: 'loaigiayto',
   anhmattruoc: 'anhmattruoc',
   anhmatsau: 'anhmatsau',
   anhchandung: 'anhchandung',
   goicuoc: 'goicuoc',
   banmuonxoabanghi: 'banmuonxoabanghi',
   banmuonasign: 'banmuonasign',
   tinhthanhpho: 'tinhthanhpho',
   quanhuyen: 'quanhuyen',
   phuongxa: 'phuongxa',
   thanhcong: 'thanhcong',
   active: 'active',
   inactive: 'inactive',
   taikhoan: 'taikhoan',
   matkhau: 'matkhau',
   xacnhanmatkhau: 'xacnhanmatkhau',
   delete: 'delete',
   listJobs: 'listJobs',
   banmuondangxuat: 'banmuondangxuat',
   doimatkhau: 'doimatkhau',
   ok: 'ok',
   matkhaukhongtrungkhop: 'matkhaukhongtrungkhop',
   thoigian: 'thoigian',
   mota: 'mota',
   productType: 'productType',
   khoangthoigian: 'khoangthoigian',
   thoigianbatdau: 'thoigianbatdau',
   thoigianketthuc: 'thoigianketthuc',
   vnd: 'vnd',
   sothutu: 'sothutu',
   daxacnhan: 'daxacnhan',
   tuchoi: 'tuchoi',
   chonngay: 'chonngay',
   admin: 'admin',
   soluong: 'soluong',
   startDate: 'startDate',
   endDate: 'endDate',
   rememberMe: 'rememberMe',
   forgotPassword: 'forgotPassword',
   createNewAccount: 'createNewAccount',
   login: 'login',
   register: 'register',
   username: 'username',
   email: 'email',
   password: 'password',
   statisticsReport: 'statisticsReport',
   txtKeyDate: 'txtKeyDate',
   txtDateUse: 'txtDateUse',
   txtDateTime: 'txtDateTime',
   totalItems: 'totalItems',
   txtLogout: 'txtLogout',
   txtManagementAccount: 'txtManagementAccount',
   txtOldPassword: 'txtOldPassword',
   txtNewPassword: 'txtNewPassword',
   txtNewPassMustBeDifferentOldPass: 'txtNewPassMustBeDifferentOldPass',
   downloadExcelVoucher: 'downloadExcelVoucher',
   txtQRCode: 'txtQRCode',
   txtType: 'txtType',
   txtUsed: 'txtUsed',
   txtNotUse: 'txtNotUse',
   mCreator: 'mCreator',
   mDesigner: 'mDesigner',
   mJob: 'mJob',
   mProductType: 'mProductType',
   mSupplier:'mSupplier',
   mDesignType: 'mDesignType',
   mEmployee: 'mEmployee',
   mAccount:'mAccount',
   mSeller: 'mSeller',
   mFulfillment: 'mFulfillment',
   mTag:'mTag',
   mDebt: 'mDebt',
   mDepositWithdrawal: 'mDepositWithdrawal',
   mFund: 'mFund',
   mPrice:'mPrice',
   mConfig: 'mConfig',
   position: 'position',
   fullname: 'fullname',
   phone: 'phone',
   phoneValidate: 'phoneValidate',
   gender: 'gender',
   dob: 'dob',
   address: 'address',
   cmtnd: 'cmtnd',
   bankName: 'bankName',
   bankAccount: 'bankAccount',
   insuranceNumber: 'insuranceNumber',
   basicSalary: 'basicSalary',
   numberOfWorkdays: 'numberOfWorkdays',
   statusAccount: 'statusAccount',
   close: 'close',
   edit: 'edit',
   all: 'all',
   dodaimatkhau: 'dodaimatkhau',
   month: 'month',
   year: 'year',
   createPayroll: 'createPayroll',
   payrollDetail: 'payrollDetail',
   detail: 'detail',
   employeeCode: 'employeeCode',
   damage: 'damage',
   createReceipt: 'createReceipt',
   receiptType: 'receiptType',
   createReceiptType: 'createReceiptType',
   createAt: 'createAt',
   receiptId: 'receiptId',
   recipientGroup: 'recipientGroup',
   receiver: 'receiver',
   payments: 'payments',
   addPayments: 'addPayments',
   moneyIn: 'moneyIn',
   moneyOut: 'moneyOut',
   filter: 'filter',
   money: 'money',
   apply: 'apply',
   type: 'type',
   receiptTypeName: 'receiptTypeName',
   receiptTypeDetail: 'receiptTypeDetail',
   note: 'note',
   copy: 'copy',
   orderListShort: 'orderListShort',
   paymentHistory: 'paymentHistory',
   debtTotal: 'debtTotal',
   customerDebt: 'customerDebt',
   partnerDebt: 'partnerDebt',
   paymentTotal: 'paymentTotal',
   role: 'role',
   profile: 'profile',
   collectMoney: 'collectMoney',
   pay: 'pay',
   other: 'other',
   staff: 'staff',
   employee: 'employee',
   da_tao_bang_luong: 'da_tao_bang_luong',
   chua_tao_bang_luong: 'chua_tao_bang_luong',
   reset: 'reset',
   create: 'create',
   phoneEx: 'phoneEx',
   search: 'search',
   noName: 'noName',
   noRole: 'noRole',
   noUserName: 'noUserName',
   noPhone: 'noPhone',
   noEmail: 'noEmail',
   enterPassword: 'enterPassword',
   emailValidate: 'emailValidate',
   noPassword: 'noPassword',
   noConfirmPassword: 'noConfirmPassword',
   reEnterPassword: 'reEnterPassword',
   noBankAccount: 'noBankAccount',
   noBankName: 'noBankName',
   title: 'title',
   designType: 'designType',
   mNumberItems: 'mNumberItems',
   numberItems: 'numberItems',
   id: 'id',
   image: 'image',
   creator: 'creator',
   designer: 'designer',
   debt: 'debt',
   supporter: 'supporter',
   accountant: 'accountant',
   tongNap: 'tongNap',
   quanLy: 'quanLy',
   daOrder: 'daOrder',
   conTon: 'conTon',
   tongConLai: 'tongConLai',
   congNoPhaiThu: 'congNoPhaiThu',
   deposit: 'deposit',
   withdawal: 'withdawal',
   withdrawer: 'withdrawer',
   withdrawerAndDepositer: 'withdrawerAndDepositer',
   groupwithdrawerAndDepositer: 'groupwithdrawerAndDepositer',
   status: 'status',
   nhomNguoiNhanNop: 'nhomNguoiNhanNop',
   nguoiNhanNop: 'nguoiNhanNop',
   phiSan: 'phiSan',
   maxPrice: 'maxPrice',
   minPrice: 'minPrice',
   thoigianlam1mau: 'thoigianlam1mau',
   sotientoithieuduocrut: 'sotientoithieuduocrut',
   sotientoithieuduocnap: 'sotientoithieuduocnap',
   sotiengiulaitoithieu: 'sotiengiulaitoithieu',
   songaygiua2lanrut: 'songaygiua2lanrut',
   soluongmauhuytrenngay: 'soluongmauhuytrenngay',
   cauhinhphisan: 'cauhinhphisan',
   hour: 'hour',
   day: 'day',
   default: 'default',
   validatePassword: 'validatePassword',
   validateEmail: 'validateEmail',
   acceptTerm: 'acceptTerm',
   lvDesign: 'lvDesign',
   maxCharacter: 'maxCharacter',
   accountNotActive: 'accountNotActive',
   activeAccount: 'activeAccount',
   activeAccountTxt: 'activeAccountTxt',
   submit: 'submit',
   avatarUrl: 'avatarUrl',
   min: 'min',
   max: 'max',
   linkSupporter: 'linkSupporter',
   somautoidaduocnhan: 'somautoidaduocnhan',
   save: 'save',
   orderName: 'orderName',
   jobType: 'jobType',
   quantityModel: 'quantityModel',
   pricePerModel: 'pricePerModel',
   inputPrice: 'inputPrice',
   design: 'design',
   model: 'model',
   timeout: 'timeout',
   activity: 'activity',
   writeComment: 'writeComment',
   commentSuccess: 'commentSuccess',
   detailDescription: 'detailDescription',
   url: 'url',
   review: 'review',
   tagTitle: 'tagTitle',
   newTag: 'newTag',
   tag: 'tag',
   imageDescription: 'imageDescription',
   noInfo: 'noInfo',
   transactionHistory: 'transactionHistory',
   noTrim: 'noTrim',
   registerSuccess: 'registerSuccess',
   huyjob: 'huyjob',
   acceptJobSuccess: 'acceptJobSuccess',
   banmuonhuyjob: 'banmuonhuyjob',
   updateSuccess: 'updateSuccess',
   approve: 'approve',
   banchapnhanphieuyeucaunay: 'banchapnhanphieuyeucaunay',
   bantuchoiphieuyeucaunay: 'bantuchoiphieuyeucaunay',
   nodata: 'nodata',
   cansualai: 'cansualai',
   hoanthanh: 'hoanthanh',
   nhan: 'nhan',
   nhanlai: 'nhanlai',
   chon: 'chon',
   xacnhan: 'xacnhan',
   accountBankName: 'accountBankName',
   confirmEmailSuccess: 'thutu',
   thutu: 'thutu',
   loading: 'loading',
   registerDesc: 'registerDesc',
   orSignupAs: 'orSignupAs',
   signup: 'signup',
   enterVerifyEmail: 'enterVerifyEmail',
   or: 'or',
   signupCreator: 'signupCreator',
   signupDesigner: 'signupDesigner',
   anchitiet: 'anchitiet',
   hienchitiet: 'hienchitiet',
   chinhsua: 'chinhsua',
   themurl: 'themurl',
   luuurl: 'luuurl',
   nhapduurl: 'nhapduurl',
   tongtien: 'tongtien',
   themtag: 'themtag',
   validateanh: 'validateanh',
   validatemotaanh: 'validatemotaanh',
   uploadanh: 'uploadanh',
   khongcoanh: 'khongcoanh',
   danglam: 'danglam',
   chodesignernhanlai: 'chodesignernhanlai',
   vuilongnhapsoluong: 'vuilongnhapsoluong',
   vuilongnhapgia: 'vuilongnhapgia',
   vuilongnhapanh: 'vuilongnhapgia',
   vuilongluumotaanh: 'vuilongnhapgia',
   vuilongnhapgiachiahet1000: 'vuilongnhapgiachiahet1000',
   giatu: 'giatu',
   creatorsereview: 'creatorsereview',
   vuilongnhapurl: 'vuilongnhapurl',
   vuilongluuurl: 'vuilongluuurl',
   vuilongnhapten: 'vuilongnhapten',
   vuilongchonanh: 'vuilongchonanh',
   huyjobthanhcong: 'huyjobthanhcong',
   xoajobthanhcong: 'xoajobthanhcong',
   alerthuyjob: 'alerthuyjob',
   alerthuyjob2: 'alerthuyjob2',
   xacnhannhanviec: 'xacnhannhanviec',
   alertnhanviec: 'alertnhanviec',
   nhanviec: 'nhanviec',
   danhgiadesigner: 'danhgiadesigner',
   vuilongdanhgia: 'vuilongdanhgia',
   guidanhgia: 'guidanhgia',
   request: 'request',
   logoutDescription: 'logoutDescription',
   xacnhanchuyentien: 'xacnhanchuyentien',
   tuchoichuyentien: 'tuchoichuyentien',
   chuyentientoi: 'chuyentientoi',
   creatornaptien: 'creatornaptien',
   sellernaptien: 'sellernaptien',
   ckthanhcong: 'ckthanhcong',
   thongbao: 'thongbao',
   copyclipboard: 'copyclipboard',
   copystk: 'copystk',
   sotienmuonnap: 'sotienmuonnap',
   xacnhancktc: 'xacnhancktc',
   xacnhanck: 'xacnhanck',
   tongcong: 'tongcong',
   ngansachconlai: 'ngansachconlai',
   loaigiaodich: 'loaigiaodich',
   hethongdangxacnhan1den10: 'hethongdangxacnhan1den10',
   designerruttien: 'designerruttien',
   fullfilmentruttien: 'fullfilmentruttien',
   dudieukienruttien: 'dudieukienruttien',
   bySigningUpIAccept: 'bySigningUpIAccept',
   termCondition: 'termCondition',
   refund: 'refund',
   refundMessage: 'refundMessage',
   chapnhanhoantien: 'chapnhanhoantien',
   sodutaikhoan: 'sodutaikhoan',
   noTeleFb: 'noTeleFb',
   noUsernameTele: 'noUsernameTele',
   xacnhancansualai: 'xacnhancansualai',
   titlexacnhancansualai: 'titlexacnhancansualai',
   rate: 'rate',
   solansuasoviechoanthanh: 'solansuasoviechoanthanh',
   content: 'content',
   dashboard: 'dashboard',
   completedQuantity: 'completedQuantity',
   businessResults: 'businessResults',
   numberEdits: 'numberEdits',
   revenue: 'revenue',
   expense: 'rxpense',
   grossProfit: 'grossProfit',
   total: 'total',
   errorNapTien: 'errorNapTien',
   assignJob: 'assignJob',
   assignJobFor: 'assignJobFor',
   priorityJob: 'priorityJob',
   priority: 'priority',
   setuutien: 'setuutien',
   soluongmauuutiennhantrenngay: 'soluongmauuutiennhantrenngay',
   soluongjobcothenhantruockhinhanjobuutien: 'soluongjobcothenhantruockhinhanjobuutien',
   yes: 'yes',
   no: 'no',
   coloxayra: 'coloxayra',
   products: 'products',
   order: 'order',
   report: 'report',
   categories: 'categories',
   properties: 'properties',
   warehouse: 'warehouse',
   sellerOrderId: 'sellerOrderId',
   shippingInfo: 'shippingInfo',
   shippingLabel: 'shippingLabel',
   trackingId: 'trackingId',
   trackingStatus: 'trackingStatus',
   exportExcel:'exportExcel',
   exportCSV:"exportCSV",
   assignWarehouse:'assignWarehouse',
   assignOrder: 'assignOrder',
   configOrder: 'configOrder',
   

}
